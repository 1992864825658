.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1024px) {
  .header-nav {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    padding-top: 100px;
    /* padding-block: 100px; */
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26,115,232));
    z-index: -1;
    overflow: auto;
    flex-direction: column;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all .3s ease-in-out;
  }
  .header-nav.is-opened {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
  .header-nav a {
    justify-content: center;
    text-align: center;
    color: #fff;
  }
}

@media only screen and (min-width: 1024px) {
  .header-nav.is-closed {
    opacity: 0;
    visibility: none;
    pointer-events: all;
  }
}

.report-loadingspinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: white;
  border-radius: 5px;
}